@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
/* font family */
@font-face {
    font-family: AVR;
    src: url('./assets/font/AvenirLTStd-Roman.otf');
}
@font-face {
    font-family: AV-HEAVY;
    src: url('./assets/font/Avenir-Heavy-05.ttf');
}
@font-face {
    font-family: AVPB;
    src: url('./assets/font/Avenir-Next-LT-Pro-Bold_5182.ttf');
}
@font-face {
    font-family: AVB;
    src: url('./assets/font/AvenirLTStd-Book.otf');
}
@font-face {
    font-family: AVL;
    src: url('./assets/font/AvenirLTStd-Light.otf');
}
@font-face {
    font-family: AVH;
    src: url('./assets/font/Avenir-Heavy.ttc');
}
/* scroll bar starts */
/* width */

::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
    visibility: hidden !important;
}

::-webkit-scrollbar:hover {
    background: #e2e2e2;
    width: 10px !important;
    height: 10px !important;
    visibility: visible !important;
}

::ng-deep .mat-card-header .mat-card-title {
    width: 280px !important;
}
::ng-deep .mat-card {
    overflow: hidden !important;
}
/* Track */

::-webkit-scrollbar-track {
    /* // box-shadow: inset 0 0 5px $gray; */
    box-shadow: inset 0 0 6px #e2e2e2;
    -webkit-box-shadow: inset 0 0 6px #e2e2e2;
    border-radius: 4px;
    background: none;
    visibility: hidden !important;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background-color: var(--std_gold);
    height: 20px;
    width: 4px;
    transition: 0.5s linear all;
    transition-delay: 0.5ms;
    border-radius: 4px;
    visibility: hidden !important;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: var(--std_gold);
    /* outline: 1px solid slategrey; */
    width: 40px !important;
    height: 40px !important;
    visibility: visible !important;
}

::-webkit-scrollbar-thumb {
    background-color: var(--std_gold);
    /* outline: 1px solid slategrey; */
    width: 40px !important;
    height: 30px !important;
    visibility:  visible !important;
}



/* scroll bar ends */

body, html {
    height: 100%;
    margin: 0;
    font-size: 14px !important;
    /* background-image: url('./assets/images/PersonalDetails-step1.png');
    background-size: cover; */
    padding: 0;
    background-color: #f8fafb !important;
    /* background-image: url('assets/images/AML_DASH.jpg'); */
}


:root{
    --std_gold : #d4ad18;
    --std_blue : #038daf;
    --std_teel : #0d404d;
    --std_green : #26a65b;
    --std_red : #cd6c39;
}


div, button, li {
    outline: none;
}
textarea {resize: none !important}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.center_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.LoginBg {
    background-attachment: fixed;
    /* background:url('assets/images/login-bg.svg'); */
    background-size: cover;
    background-repeat: no-repeat;
}

.dateHightlighter {
    background: var(--std_gold);
    border-radius: 50%;
}

.font-normal {
    font-weight: normal !important;
}

.LoginBg {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}
.back_button_div {
    padding-left: 16px !important;
}
.outer_circle {
    height: 148px;
    margin-top: -93px;
    width: 148px;
    border-radius: 50%;
    margin-left: 27px;
    background: #f5f7f9;
    animation: Anim1 5s infinite linear reverse;
}

.inner_circle {
    width: 79px;
    height: 79px;
    margin: 40px auto;
    background: #f1f4f6;
    animation: Anim2 5s infinite linear reverse;
    border-radius: 50%;
}

.RED_BACKGROUND {
    background: red;
    text-align: center !important;
}

.GREEN {
    background: green;
    text-align: center !important;
}
.ORANGE {
    background: orange;
    text-align: center !important;
}

.LoginBg::after {
    content: '';
    width: 120px;
    border-radius: 50%;
    height: 120px;
    /* border-top-left-radius: 117px; */
    /* border-bottom-left-radius: 117px; */
    position: absolute;
    top: calc(50% - 50px);
    background: rgb(232, 236, 240);
    right: -60px;
    overflow: hidden;
    animation: Anim 5s infinite;
}

.LoginBg::before {
    content: '';
    width: 292px;
    border-radius: 50%;
    height: 292px;
    animation: Anim2 5s infinite linear;
    position: absolute;
    top: calc(50% - 136px);
    background: #f2f4f8;
    right: -145px;
    overflow: hidden;
}

.welcomeBg {
    background: url('assets/images/MaskGroup5.png');
    background-size: cover;
}
.welcomeBgNew{
    background: url('assets/images/PilotScreen_Welcome.144.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.pos-abs {
    position: absolute;
}

.pos-rel {
    position: relative;
}

.copyright {
    text-align: right;
}

.copyright.pos-abs {
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

.pl-30 {
    padding-left: 30px !important
}

.pl-15 {
    padding-left: 15px !important
}

.mat-primaryCus {
    background: var(--std_teel);
    color: #fff;
}

.primaryStrCus {
    color: var(--std_teel);
}

.wizard {
    border: 1px solid #cccccc12;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgba(204, 204, 2014)
}

.tabnavbg {
    background: rgba(247, 247, 247, 0.56);
}

.tabnavbgonly {
    background: rgba(247, 247, 247, 0.56);
}

.wiz_title {
    display: flex;
    align-items: center;
    font-size: 20px;
}
.title {
    font-size: 17px;
    font-weight: 600;
    margin: 50px 0px;
}
.settlement_title {
    padding-bottom: 8px;
    flex-wrap: wrap;
}

.min-width-180 {
    width: 180px;
    min-width: 180px !important;
}

.cursor-none {
    cursor: not-allowed !important;
    opacity: 0.3;
}
.auto_y_table_overflow{
overflow-y: auto;
}

span.Badge {
    background: var(--std_gold);
    color: #fff;
    /* padding: 0px 5px; */
    width: 17px;
    height: 17px;
    margin-right: 10px;
    display: block;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    position: relative;
    /* left: -5px; */
}

span.Badge p {
    font-size: 13px;
    font-weight: normal;
    font-family: AVH;
}

.tabnav ul {
    padding-left: 10px;
    list-style: none;
}

.wizard .mat-tab-body-wrapper form {
    margin: 2% auto 2% auto;
}

.tabnav {
    width: 100%;
}

.tabnav ul>li:after {
    content: '';
    width: 15px;
    height: 15px;
    background: rgba(218, 217, 217, 0.69);
    position: absolute;
    right: 5px;
    top: 0px;
    border-radius: 50%;
}

.tabnav ul>li {
    position: relative;
    margin: 20px 0px;
    font-size: 13px;
    color: var(--std_teel);
    padding-right: 20px;
}

.tabnav ul>li.active {
    font-weight: bold;
    font-family: AVR;
}

.tabnav ul>li.completed:after {
    background-image: url(assets/images/checked.svg);
    background-color: #fff;
    background-size: cover;
}

.tabnav ul>li.uncompleted:after {
    background-image: url(assets/images/cross.png);
    background-color: #fff;
    background-size: cover;
}

.tableMenu>li::before {
    content: '';
    width: 15px;
    height: 15px;
    background: rgba(218, 217, 217, 0.69);
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 50%;
}

.tableMenu>li {
    position: relative;
    margin: 20px 0px;
    font-size: 13px;
    color: var(--std_teel);
    padding-left: 20px;
    font-family: AVR;
}

.tableMenu>li.completed::before {
    background-image: url(assets/images/checked.svg);
    background-color: #fff;
    background-size: cover;
}

.redColor{
    color:#ed554b;
}

.tableMenu>li.uncompleted::before {
    background-image: url(assets/images/cross.png);
    background-color: #fff;
    background-size: cover;
}

.tableMenuTitle {
    color: #d4d4d4;
    padding-left: 11px;
    font-size: 12px;
    margin-bottom: 10px;
}

/* change text color into white  */

.fontwhite {
    color: #ffffff;
}

/* set padding  */

.setPadding {
    padding-left: 100px;
    box-sizing: border-box;
}

.login .footerMnu {
    margin-left: 60px !important;
}

.tabInnerTitle>span {
    background: #e9eeef;
    padding: 4px 10px;
    font-size: 13px;
}

.tabInnerTitle {
    margin: 0;
    padding-left: 15px;
    padding-top: 6px;
    padding-bottom: 15px;
}

.row_tab {
    width: 65%;
    margin-left: 44px;
}

.welcomeBg h2 {
    font-size: 41px;
    font-weight: 600;
    /* margin-left: 25px; */
}

.yellow_square {
    position: absolute;
    width: 31px;
    height: 35px;
    top: calc(50% - 12px);
    /* left: 0px; */
    left: -15px;
    background: var(--std_gold);
}

.blue_rectangle {
    position: relative;
}

.blue_rectangle::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 20px;
    background: var(--std_teel);
    left: -60px;
}

.card_title {
    border-bottom: 1px solid rgba(212, 203, 203, 0.37);
    padding: 0px 15px 17px 15px;
    color: var(--std_teel);
    font-weight: 600;
}
.card_head .mat-card-title , .product_card .mat-card-title{
    white-space: nowrap;
    width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.prodDescription{
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.plainSelect {
    padding-left: 10px;
    padding-right: 10px;
    background: #fafafa;
    border: 1px solid #eee;
    border-radius: 5px;
    color: #144553;
}

/* Material design Overriedes */

.mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 1.6px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(182, 183, 183, 0.85);
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: var(--std_gold);
}

.form_wizard .mat-ink-bar {
    height: 3px !important;
}

.form_wizard .mat-tab-label {
    font-family: AVR;
    color: #000;
    font-size: 15px;
    font-weight: 600;
}

.form_wizard .mat-tab-label-container {
    background: #fff;
}

.mat-form-field-label-wrapper {
    font-size: 13px;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: #b6b6b6ab !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: var(--std_gold) !important;
}

.iop th.mat-header-cell {
    text-align: left;
    border: 1px solid #e5ddddc7;
    /* background: #F8F8F9; */
    padding: 10px 10px 10px 13px !important
}

.iop .mat-cell {
    border: 1px solid #e5ddddc7;
    padding: 2px 10px 2px 13px !important;
}

.iop .mat-elevation-z8 {
    box-shadow: none;
}

.aml .mat-card:not([class*=mat-elevation-z]) {
    box-shadow: 1px 0px 1px -1px rgba(72, 67, 67, 0.19), 0 1px 1px 0 rgba(127, 125, 125, 0.14), 0 1px 3px 0 rgba(204, 201, 201, 0.12);
}

.aml .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0px;
}

.iop .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0px;
}

.iop .mat-form-field-underline, .aml .mat-form-field-underline.ng-tns-c7-0.ng-star-inserted {
    display: none;
}

.iop .mat-form-field-wrapper {
    margin-bottom: 0;
}

.iop .mat-form-field-appearance-legacy .mat-form-field-infix {
    border: none;
}

.aml .mat-form-field-underline.ng-tns-c7-0.ng-star-inserted {
    display: none;
}

.aml .mat-form-field-underline {
    display: none;
}

.Hide label.mat-form-field-label {
    display: none !important;
}

.aml .mat-form-field-infix {
    border: none;
}

.wht-nowrap {
    white-space: nowrap;
}

.wht-nowrap.alignedTable th, .wht-nowrap.alignedTable td {
    padding-left: 5px;
    padding-right: 5px;
}

@media only screen and (min-width: 768px) {
    .mat-tab-body-content, .mat-tab-body.mat-tab-body-active, .mat-tab-body, .mat-tab-body-wrapper {
        overflow: visible !important;
    }
}

/* .mat-tab-body-content{
    overflow: visible !important;
} */

@-webkit-keyframes Anim {
    25% {background-color: #f2f4f8;}
    100% {background-color: rgb(232, 236, 240);}
  }


  @keyframes Anim2 {
    25% {background-color: rgb(232, 236, 240);}
    100% {background-color: #f2f4f8;}
  }

.control_buttons, .control_back_button {
    letter-spacing: 3px;
    font-weight: 600;
}

.addBtn {
    width: 25px;
    background: var(--std_gold);
    border-radius: 50%;
    padding: 0px 6px;
    border: 0;
    box-sizing: border-box;
    height: 25px;
    cursor: pointer;
}

.BusAddCnctBtn .addBtn {
    margin-top: 20px;
}

.BusAddCnct {
    width: calc(100% - 60px);
    float: left;
}

.addBtn>img, button.editBtn>img, button.delBtn>img {
    width: 100%;
    position: relative;
    top: -1px;
}

.BusAddCnctBtn {
    width: 30px;
    float: left;
}

button.delBtn {
    width: 25px;
    height: 25px;
    background: var(--std_teel);
    border: 0;
    border-radius: 50%;
    padding: 0px 7px;
    box-sizing: border-box;
    cursor: pointer;
}

button.editBtn {
    width: 25px;
    height: 25px;
    background: var(--std_gold);
    border: 0;
    border-radius: 50%;
    padding: 0px 7px;
    box-sizing: border-box;
    cursor: pointer;
}

.check_container {
    display: block;
    font-family: AVH;
    position: relative;
    padding-left: 26px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */

.check_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    border: 1px solid #2d2b2b;
    border-radius: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
}

/* On mouse-over, add a grey background color */

.check_container:hover input~.checkmark {
    background-color: transparent;
}

.eyeview {
    cursor: pointer !important;
}

.table_head_back img{
    width: 16px;
    vertical-align: text-bottom !important;
}
img.table_head_back{
    width: 16px;
    vertical-align: text-bottom !important;
}
.backbtn{
    border: none;
    box-shadow: none;
}

.paraFont {
    font-size: 14px;
    text-align: left;
}

.register {
    margin-top: 10%;
    margin-left: 2%;
    background-color: #f2f5f6;
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.register a {
    color: var(--std_gold);
}

.register p {
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 600;
}

/* When the checkbox is checked, add a blue background */

.check_container input:checked~.checkmark {
    background-color: #f8fafb;
    border: none;
    background: var(--std_gold);
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.check_container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.check_container .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.rounded_yellow_button {
    background-color: var(--std_gold)!important;
    padding: 0px 35px !important;
    color: #ffff;
}

.mat-primaryStrCus {
    border: 1px solid #0d0d0d !important;
    color: var(--std_teel);
}

.copyright p {
    font-size: 14px;
    font-weight: 600;
    color: #080808;
    margin-bottom: 5px;
}

.footerMnu li {
    display: inline;
    cursor: pointer;
}

.footerMnu li:not(:first-child) {
    margin-left: 50px;
}

.footerMnu a, .footerMnu a:hover, .footerMnu a:focus {
    color: #717171;
}

.footerMnu {
    margin-top: 25px;
    padding-left: 0px;
}

ul.footerMnu.align-self-end.pl-15.mb-4 a {
    font-family: AVL;
    font-size: 14px;
}

table.mat-elevation-z8 {
    width: 100%;
}



.contentWrapper {
    width: calc(100% - 200px);
}

.sidewrapper, .contentWrapper {
    float: left;
    padding: 0px 15px;
    box-sizing: border-box;
    overflow: auto;
}

.closeMenu {
    width: 50px !important;
}

.openContent {
    width: calc(100% - 50px) !important;
}

.px-15 {
    padding: 0px 15px !important;
}

.px-30 {
    padding: 0px 30px !important;
}

.passwordIndicator ul {
    list-style: none;
    padding: 0;
}

.passwordIndicator ul>li {
    position: relative;
    padding-left: 20px;
    margin: 10px;
}

.passwordIndicator ul>li::before {
    content: url(assets/images/valid.svg);
    position: absolute;
    left: 0;
    top: 2px;
}

.passwordIndicator ul>li.ValidPassword::before {
    content: url(assets/images/invalid.svg) !important;
}

.passwordIndicator {
    position: absolute;
    background: #f1f2b1;
    z-index: 9;
    padding: 15px;
    box-shadow: 0px 0px 3px 1px;
    display: none;
    font-size: 12px;
}

.passwordIndicator {
    bottom: 35px;
    left: 100%;
    width: 330px;
}

.onFocusPopup:hover .passwordIndicator {
    display: block;
}

.ValidPassword {
    color: red;
}

@media only screen and (max-width: 767px) {
    .passwordIndicator {
        top: 100%;
        left: 30px;
        width: 330px;
        height: 183px;
    }
    .LoginBg::before {
        top: calc(50% - 119px);
        width: 230px;
        height: 230px;
    }
    .LoginBg::after {
        content: '';
        width: 95px;
        border-radius: 50%;
        height: 88px;
        top: calc(50% - 50px);
    }
    .yellow_square {
        top: calc(50% - -3px);
    }
    .welcomeBg {
        display: none;
    }
    .col-sm-12.com-md-2.col-lg-2.col-xl-2.tabnavbg::before {
        top: 0px;
    }
    .footerMnu li {
        font-size: 13px;
    }
    .footerMnu li:not(:first-child) {
        margin-left: 20px;
    }
    .copyright {
        text-align: center;
    }
}

@media only screen and (max-width: 425px) {
    .footerMnu li {
        font-size: 11.6px;
    }
    .login .footerMnu {
        margin-left: 7px !important;
    }
}

.addBtn:disabled {
    background: #c1c1c1;
    cursor: no-drop;
}

.mat-form-field-wrapper {
    margin-bottom: 20px;
}

.mat-pad-0 .mat-form-field-wrapper {
    margin-bottom: 0px !important;
}

.mat-pad-0 .pad-15 {
    padding-left: 15px !important;
    left: 15px;
    top: 44px;
    color: #ea0000;
}
.aml .mat-form-field-wrapper {
    margin-bottom: 0px;
}

/*  */

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background: var(--std_gold);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(208, 205, 206, 0.54);
}

.mat-menu-content ul {
    list-style: none;
    padding: 0px 10px;
    font-size: 13px;
    margin: 0;
    color: var(--std_teel);
}

.mat-menu-content .icon {
    width: 30px;
    box-sizing: border-box;
    padding: 5px
}

.mat-menu-content .iconContent {
    width: calc(100% - 30px);
    transition: all 0.2s;
}

.gearmenu .iconContent{
    cursor: pointer;
}

.mat-menu-content .icon, .mat-menu-content .iconContent {
    float: left;
    display: block;
    width: 100%;
}
.mat-menu-content .iconContent:hover {
    color: #d3ae33;
}


.mat-menu-content li {
    margin: 5px auto;
    width: 100%;
    display: flex;
    align-items: center;

}

.main_title {
    padding: 20px 15px 0px 15px;
}

.main_title h3 {
    font-size: 25px;
}

.tabnavbg::before {
    width: 100%;
    height: 53px;
    background: #f8f9f9;
    position: absolute;
    top: -50px;
    border-top-right-radius: 5px;
    content: '';
    left: 1px;
}

.tableSelect {
    width: 200px;
}

.tableInput {
    width: 75px;
}

.tableInputComment {
    width: 150px;
}

.closep button {
    transform: rotate(45deg);
}

.totalTaxRow {
    flex-direction: row;
    display: flex;
    justify-content: space-between;;
}

.totalTax {
    display: flex;
    flex-direction: column;
    float: right;
    padding: 20px 10px;
    color: var(--std_teel);
    font-size: 13px;
}

.totalTaxLabel {
    width: 180px;
}

.totalTaxData {
    font-weight: bold;
}

.dot_indicators ul li {
    position: relative;
    display: flex;
    margin-right: 30px;
    align-items: center;
}

.dot_indicators ul li::before {
    content: '';
    height: 13px;
    width: 13px;
    background: #D8B53C;
    position: absolute;
    border-radius: 50%;
}

.dot_indicators ul li:nth-child(1)::before {
    background: #EC644B;
}

.dot_indicators ul li:nth-child(2)::before {
    background: #D8B53C;
}

.dot_indicators ul li:nth-child(3)::before {
    background: var(--std_green);
}

.dot_indicators ul li:nth-child(4)::before {
    background: #3498DB;
}

.dot_indicators ul {
    display: flex;
    /* padding-lef{t: 0px; */
    list-style: none;
}

.dot_indicators p {
    margin-bottom: 0px;
    margin-left: 24px;
}

.redbr {
    border-left: 10px solid #EC644B !important;
}

.greenbr {
    border-left: 10px solid  #26a65b !important;
}

.goldbr {
    border-left: 10px solid  #d4ad18 !important;
}
.bluebr {
    border-left: 10px solid #3498DB !important;
}

.graybr {
    border-left: 10px solid #ccc !important;
}

.redbr_bef::before {
    position: absolute;
    width: 5px;
    content: "";
    background: red;
    height: 100%;
    left: -5px;
    top: 0px;
}

.greenbr_bef::before {
    position: absolute;
    width: 5px;
    content: "";
    background: var(--std_green) !important;
    height: 100%;
    left: -5px;
    top: 0px;
}

.goldbr_bef::before {
    position: absolute;
    width: 5px;
    content: "";
    background: #D8B53C !important;
    height: 100%;
    left: -5px;
    top: 0px;
}

.margin-row {
    margin: 0 -15px;
}

.wizard.margin-row {
    box-shadow: none !important;
}

.wizard.margin-row .mat-tab-labels {
    display: flex;
    padding: 2px 0px;
    background: #fafafa;
}

.wizard.margin-row .mat-tab-label-container {
    background: none;
}

.page_title,.popupTitle_rules {
    color: var(--std_teel);
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}
.page_title_collapse,.popupSubTitle_rules{
    color: var(--std_teel);
    font-size: 15px;
    font-weight: bold;
    margin: 0;
}

.blueFont{
    color: var(--std_teel);
    word-break: break-all;
}
.titleCardSelect .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
}

.titleCardSelect .mat-form-field-underline, .titleCardSelect .mat-form-field-label-wrapper {
    display: none
}

.titleCardSelect {
    border: 1px solid #E0E0E0;
    background: #F8F9F9;
    border-radius: 2px;
    padding: 0px 10px;
}

.titleCardSelect .mat-form-field-infix {
    padding: 10px 0px 10px 0px;
    border-top: 0;
    font-size: 12px;
}
.mini_icons .titleCardSelect .mat-form-field-infix {
  width: 250px;
}

.card_head {
    border-bottom: 1px solid rgba(212, 203, 203, 0.37);
    padding: 0px 15px 10px 15px;
    color: var(--std_teel);
    align-items: center;
    font-weight: 600;
}

.dot_ind ul li {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.dot_ind ul li::before {
    content: '';
    height: 13px;
    width: 13px;
    background: #D8B53C;
    position: absolute;
    border-radius: 50%;
}

.dot_ind ul li:nth-child(1)::before {
    background: #ed554c;
}

.dot_ind ul li:nth-child(2)::before {
    background: var(--std_gold);
}

.dot_ind ul li:nth-child(3)::before {
    background: var(--std_green);
}
.dot_ind ul li:nth-child(4)::before {
    background: #3698db;
}
.dot_ind ul li:nth-child(5)::before {
    background: #cccccc;
}

.dot_ind ul {
    display: flex;
    padding-left: 5px;
    list-style: none;
    margin-bottom: 0;
    justify-content: space-between;
}

.dot_ind p {
    margin-bottom: 0px;
    padding-left: 17px;
    margin-top: 1px;
}

.bgWhite {
    background: #fff;
}

.gridCtrls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}

.gridCtrls span>img {
    width: 37px;
    cursor: pointer;
}

.srchGroup {
    display: flex;
    position: relative;
}

.srchIcon img {
    width: 33px;
    padding: 10px 9px;
    position: absolute;
    right: 0;
    top: 1px;
    background: #ccc;
    cursor: pointer;
}

.srchTextbox input {
    border: 1px solid #ccc;
    padding: 8px 33px 8px 8px;
    border-radius: 2px;
    font-size: 13px;
    min-width: 250px;
}

.checkBoxlign .mat-checkbox-layout {
    position: relative;
    top: 3px;
}

.pointer {
    cursor: pointer;
}


.users_mod .mat-table {
    border-collapse: separate;
    padding: 0;
}
.table-responsive.users_mode {
    overflow-y: hidden;
}
.users_mode .mat-row {
    height: auto !important;
}

.users_mode .mat-cell {
    padding: 5px 10px 5px 8px !important;
}

.red {
    background: #EC644B;
}

.green {
    background: var(--std_green);
}

.gray {
    background: #ccc;
}

.blue {
    background: #3498DB;
}

.gold {
    background: var(--std_gold);
}

.ash {
    background: #d0cece;
}
.voided_red{
    background: #EC644B;
}
.pale_gold{
    background: var(--std_gold);
}
.paleyellowbr{
    border-left: 8px solid #fde599 !important;
}
.status {
    padding: 3px 6px;
    font-size: 15px;
    margin-bottom: 3px;
    text-align: center;
    color: #fff;
}
.status_width{
    width: 100px;
}
.bs {
    width: 25px;
    height: 25px;
    text-align: center;
    margin: 0 auto;
    background: var(--std_teel);
    border-radius: 50%;
     color:#fff;
    font-weight: 600;
    line-height: 24px;
    font-size: 12px;
}

.mat-table__wrapper .mat-table {
    min-width: auto !important;
    width: 100% !important;
}

.mat-header-row {
    width: 100%;
}

.mat-row {
    width: 100%;
}



.chartstyle2 {
    width: 100% !important;
    height: 240px !important;
}

.order .mat-tab-body-content, .mat-tab-body.mat-tab-body-active, .mat-tab-body, .mat-tab-body-wrapper {
    overflow: hidden !important;
}

.searchbox input {
    background: url(./assets/images/mag.png);
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 10px;
    padding: 4px 37px;
    border: 1px solid #e4dddd;
    border-radius: 5px;
}

.searchbox {
    align-self: center;
}



.divider {
    border-bottom: 1px solid #ccc;
    display: block;
    margin-right: -39px;
    margin-left: -39px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.popupTitle {
    color: var(--std_teel);
    font-size: 18px;
    font-weight: bold;
}

button.AddFilter {
    color: var(--std_gold);
    font-weight: bold;
    cursor: pointer;
    padding: 0px 15px;
    background: none;
    border: 0;
}
button.AddFilter:disabled{
    color: #ccc;
}
.mat-header-cell {
    font-weight: 600;
    font-size: 15px;
    color: var(--std_teel) !important;
}

.mat-menu-panel {
    max-width: 500px !important;
    min-height: unset !important;
}

span.filterIcon {
    width: 28px;
    padding: 5px;
    display: block;
}

span.filterIcon img {
    width: 100%;
}

.editBtnBnk {
    width: 15px;
    cursor: pointer;
    margin: 0px 10px;
}
.btnBack {
    border: 1px solid #ccc !important;
}
.btnBack:before {
    content: "<";
    margin-right: 6px;
}

.bankDetailTable p {
    font-weight: 600;
    margin: 0;
}

.checkbox_width {
    padding-left: 20px !important;
    padding-top: 8px !important;
    min-width: 55px !important;
    width: 55px !important;
}

.tabnav li {
    cursor: pointer;
}

.mat-dialog-title .ModelTitle {
    color: var(--std_teel);
}

.striped .mat-row:nth-child(even) {
    background-color: #fff;
}

.striped .mat-row:nth-child(odd) {
    background-color: #eee;
}



.users_mod .mat-row {
    height: auto !important;
}

.fileUploader {
    height: 250px;
    width: 100%;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.fileUploader input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #EC644B;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.fileNameImg {
    width: 100px;
    margin: 0 auto;
}

.fileUploader {
    height: 250px;
    width: 100%;
    position: relative;
}

.fileName {
    min-height: 50px;
    color: var(--std_teel);
}

.fileNameCnr {
    display: flex;
    width: 100%;
    cursor: pointer;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
}

.fileNameCnr img {
    width: 50px;
    margin-bottom: 10px;
}

.ManulPro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
}

.ManulPro span {
    font-size: 20px;
    color: var(--std_teel);
}

.closep button, .closep {
    background: transparent;
    transform: none;
}

/* .closep img{
    width: 33px;
} */

.expander {
    background: transparent;
    box-shadow: none;
}

/* .expander .mat-expansion-panel:not([class*=mat-elevation-z]){
    box-shadow: none !important;
} */

.expand_toggle {
    text-align: center;
}

.expand_toggle img {
    cursor: pointer;
    width: 40px;
}

.delBtn img {
    width: 12px;
    transform: rotateZ(45deg);
    position: relative;
    top: -1px;
}

.delBtn button {
    background: var(--std_gold);
    border: 0;
    border-radius: 50%;
}

.trash_ button, .editBtn button {
    background: transparent;
    border: none;
}

.trash_ img, .editBtn img {
    width: 28px;
}

.tabselect .mat-select-placeholder {
    color: black !important;
}

.selallcocline {
    position: relative;
    top: 3px;
}

.bs_container {
    display: flex;
    align-items: center;
}

.bs_container .bs {
    margin: unset !important;
    margin-right: 5px;
}

.bs_container div:nth-child(2) {
    width: 70%;
    margin-left: 10px;
}

.check_sticky {
    min-width: 50px !important;
    border-left: 0px !important;
}

.cust_sticky {
    left: 50px !important;
}

.selall .mat-checkbox-layout {
    margin-bottom: 0pc;
    margin-right: 10px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #10404d !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #10404d !important;
}

.RadioBtnclmnGroup {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
}

:host ::ng-deep .black-overlay {

    position: fixed !important;
}

.fileNameCnr input {
    opacity: 0;
    max-width: 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.swatch ul li:before {
    content: '';
    width: 15px;
    height: 15px;
    top: 3px;
    position: absolute;
    left: -26px;
}

.swatch ul li:nth-child(1)::before {
    background: #0c4149;
}

.swatch ul li:nth-child(2)::before {
    background: #0c6881;
}

.swatch ul li:nth-child(3)::before {
    background: #128aaf;
}

.swatch ul li {
    position: relative;
    margin-bottom: 15px;
}

.swatch ul {
    padding-left: 26px;
    list-style: none;
}



.swatch ul li p:nth-child(1) {
    font-weight: 600;
}

.swatch ul li p{
    color: #666666;
}

.innerWizard {
    width: 100%;
}

.tableRowEdit .editRow::before {
    content: url(assets/images/editico.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .summary::before {
    content: url(assets/images/SUMMARY.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .disabledRowEdit::before {
    content: url(assets/images/disabledEdit.svg);

}
.disabledRowEdit{
    color: #a0a2a5;
    cursor: no-drop !important;
}

.tableRowEdit .cloneRow::before {
    content: url(assets/images/copy.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

.tableRowEdit .morerow::before {
    content: url(assets/images/vieweye.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .approveRow::before {
    content: url(assets/images/rowapprove.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .unflaged {
    content: url(assets/images/unflag.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .rowReject::before {
    content: url(assets/images/rowreject.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

.tableRowEdit .deleterow::before {
    content: url(assets/images/deletesmall.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .voidDoc::before {
    content: url(assets/images/void.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .read::before {
    content: url(assets/images/doublecheck.svg);
    width: 25px;
    height: 25px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .unread::before {
    content: url(assets/images/tick.svg);
    width: 25px;
    height: 25px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .rowArchive::before {
    content: url(assets/images/archive.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

.tableRowEdit .rowDownload::before {
    content: url(assets/images/download-arrow.svg);    
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

.tableRowEdit .rowComment::before {
    content: url(assets/images/comment.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

.tableRowEdit .rowAttach::before {
    content: url(assets/images/clip.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .history::before {
    content: url(assets/images/history.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .dispute::before {
    content: url(assets/images/mace.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .cancel::before {
    content: url(assets/images/closesm.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .approval::before {
    content: url(assets/images/verification-symbol-of-a-man.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .approvalCancel::before {
    content: url(assets/images/cancelApprove.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

.tableRowEdit .viewRow::before {
  content: url('./assets/images/eye-blue.svg');
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 4px;
  top: -2px;
}

.tableRowEdit .addRow::before {
  content: url('./assets/images/add-dash-small.svg');
  width: 8px;
  height: 15px;
  position: relative;
  margin-right: 8px;
  top: -2px;
}
.tableRowEdit li {
    cursor: pointer;
    align-items: center;
}
.innerWizard .mat-tab-label-container, .innerWizard .mat-tab-header {
    display: none;
}
.innerWizardDisabled .mat-tab-label-container, .innerWizardDisabled .mat-tab-header {
    display: block !important;
}
.breadCrumbs ul {
    list-style: none;
    padding: 0;
    color: var(--std_teel);
}

.breadCrumbs ul>li {
    cursor: pointer;
}

.breadCrumbs ul>li::before {
    content: "<<";
    margin-right: 4px;
}

.formparent {
    background: #f8fafb;
}



.bc-seperate table{
   border-collapse: separate;
}

.transparentcard {
    background: none;
}

.actions img {
    width: 20px;
    cursor: pointer;
    margin-right: 5px;
}

.canceldel {
    width: 15px !important;
}

.actionCol {
    width: 70px;
}

.editTableCntrols .mat-form-field-wrapper {
    margin: 0
}
.editTableCntrols .mat-form-field-infix{
    border-top: 0px;
}
.action_array{
    width: 45px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.action_array .edit_row {
    background: url('assets/images/blue_pencil.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .archive_row{
    /* background: url("/assets/images/archive.svg"); */
    background: url('/assets/images/archive-icon.png');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}

.action_array .delete {
    background: url('assets/images/dump.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .edit {
    background: url('assets/images/editico.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}

.action_array .save {
    background: url('assets/images/save.png');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .download {
    background: url('assets/images/download.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .restore {
    background: url('./assets/images/reloadFW.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .history {
    background: url('assets/images/history.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}

.action_array .cancel {
    background: url('assets/images/close.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .add {
    background: url('assets/images/rowadd.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .read {
    background: url('assets/images/read.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .view {
    background: url('assets/images/vieweye.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .unread {
    background: url('assets/images/unread.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .unflaged {
    background: url('assets/images/unflag.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .flagged {
    background: url('assets/images/flagged.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}

.action_array button {
    margin-right: 5px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background
{
    background: #0c6881 !important;
}
.prodpopup th.mat-header-cell:not(:first-child) {
    width: 200px;
}
.mat_cust_haed,.card_head,.mat-card-header-ltr{
    padding: 10px;
    color: #000;
    border-bottom: 1px solid #ccc;
    min-height: 46px;
}

.mat_cust_haed .mat-card-title,.card_head .mat-card-title,.mat-card-header-ltr .mat-card-title{
    margin-bottom: 0px !important;
}
.card_head .mat-card-header-text{
    margin: 0 !important;
}
.mat_cust_haed .mat-card-header {
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.mat-card-header-ltr {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.deactive_list .mat-card-header {
    display: flex !important;
    flex-direction: unset !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.cust_card
{
    background: #eeeeee;
}
.cust_card .mat-card-avatar{
    width: unset;
    height: unset;
    border-radius: unset;
}
.default_selector
{
    cursor: pointer;
}
.mat-card-header .mat-card-title{
    font-size: 17px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.9em;
    white-space: nowrap;
}
.cust_card .mat-card-content {
    padding: 10px;
}
.tax{
    width: 25px;
}
.rotate_infinite_anim:hover{
    animation:infinite-spinning 2s infinite ;
}
.shake_anim{
    animation: shake 1.2s cubic-bezier(.36,.07,.19,.97) both ;
    animation-iteration-count: 8;
}
.shake_anim:hover{
    animation: none !important;
}
.no_chart{
    text-align: center;
}
.no_chart img{
    max-width: 100%;
    overflow: hidden;
}
.lineGraph{
    padding: 0;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lineGraph > li {
    display: inline-flex;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
}
.lineGraph > li::before {
    content: '';
    width: 10px;
    height: 10px;
    top: 7px;
    position: absolute;
    left: 0px;
    border: 5px solid;
}
.card_sub_title{
    color: #c4bdbd;
    font-family: AVH;
    margin-bottom: 0px;
}
.choosebutton{
    background: #D4AD18;
    border: none;
    padding: 8px 25px;
    border-radius: 4px;
    color: #fff;
    overflow: hidden;
    position: relative;

}
.bankqn{
    margin: 20px 0px;
    padding: 0px;
}
.choosebutton input{
    max-width: 100%;
    left: 0px;
    height: 100%;
    opacity: 0;
    top: 0px;
    position: absolute;
    cursor: pointer;
}
.newtrash{
    background: var(--std_teel) !important;
    margin-right: 10px;
}
.bankedits img {
    width: 20px;
}
.table_outer {
    box-shadow: 0px 3px 3px 0px #eee;
}
.table_outer .table_details:nth-child(even){
    background-color: #fff;
}
.table_outer .table_details:nth-child(odd) {
    background-color: #eee;
}

.table_header.row {
    background: #fff;
    padding: 14px 13px;
    border-bottom: 1px solid #eee;
}

.table_details.row {
    padding: 14px 10px;
    background: #F8F8F9;
    color: #000000;
}
.selector {
    text-align: center;
    border-radius: 4px;
    padding: 10px;
    background: #F8F9F9;
    cursor: pointer;
}

.selector p {
    display: inline;
    font-size: 11px;
    font-family: AVR;
    font-weight: 600;
    padding-left: 5px;
}

.selector image {
    width: 20px;
}

.selector.switch_color {
    background: var(--std_teel);
}

.selector.switch_color p {
    color: #fff;
}

.selector img {
    width: 25px;
    margin-right: 6px;
}
.userform {
    margin-top: 20px;
}

.user_edits {
    padding: 0px 30px;
}

.user_edits {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
}
.user_edits div:nth-child(2) button {
    border: 1px solid #d4ae2a;
}

.user_edits div:nth-child(2) button:nth-child(1){
    border: 1px solid #0d404d;
    color: var(--std_teel);
    background-color: transparent!important;
    padding: 0 20px!important;
}

.user_edit_icons img {
    width: 20px;
    cursor: pointer;
}

.userData {
    border: 1px solid #ebe2e2b8;
    border-radius: 5px;
    overflow: auto;
}

.userData th {
    background: #F8F8F9;
    border-right: 1px solid #eee;
    font-size: 14px;
}

.userData tr td {
    background: #fff;
    border-right: 1px solid #ebe2e2b8;
}

.userData table {
    margin-bottom: 0px;
}

.user_edit_table {
    width: 100%;
}

.user_edit_table tr td {
    border: none;
}

.userData {
    margin-top: 20px;
}

/* .usertab {
    background: #fff;
} */

.overlay_main {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(121, 85, 72, 0.59);
    z-index: 111;
}

.body_modal {
    width: 35%;
    background: #fff;
    padding: 10px;
    margin: 75px 35%;
    position: absolute;
    border-radius: 5px;
}
.sub_{
    position: fixed;
    width: 100%;
    height: 100%;
    background: transparent;
}

.body_modal p {
    font-weight: 700;
    text-align: center;
    padding: 7px;
}

.input_wrap {
    text-align: center;
    margin-bottom: 16px;
}

.sub_button {
    text-align: right;
}

.input_wrap input {
    width: 70%;
}
.usertab{
    min-height: 400px;
}


.contact_person img{
width: 20px;
margin-right: 20px;
}

.Investor
{
  background: #d8b53c;
  line-height: 22px;
  display: block;
  font-size: 12px;
  width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 50%;
  border-radius: 50%;
  font-weight: 600;
  color: #fff;
  margin-top: 4px;
}
.Bo
{
    background: #32ff7e;
    line-height: 22px;
    display: block;
    font-size: 12px;
    width: 22px;
    height: 22px;
    text-align: center;
    border-radius: 50%;
    border-radius: 50%;
    font-weight: 600;
    color: #fff;
    margin-top: 4px;
}
.controller{
    background: #3c40c6;
    line-height: 22px;
    display: block;
    font-size: 12px;
    width: 22px;
    height: 22px;
    text-align: center;
    border-radius: 50%;
    border-radius: 50%;
    font-weight: 600;
    color: #fff;
    margin-top: 4px;
}
.types_circle span span{
    margin-right: 10px;
}
.phoneAdd{
    width: unset;
}
.phonearray{
    align-items: baseline;
}
.registerBtnWrp {
    border: 1px solid #ccc;
    padding: 10px;
    max-width: 400px;
    margin-top: 10%;
    margin-bottom: 10%;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 1px #ccc;
    text-align: center;
    font-size: 18px;
    color: var(--std_teel);
    min-height: 195px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.regCtrl .mat-form-field-wrapper {
    margin: 0;
}
.mx-wid-250{
    max-width: 250px;
}
.regimg {
    width: 50px;
    margin: 10px auto;
}
.regimg img {
    width: 100%;
}
.regTitle p {
    margin: 0;
}
.wiz_title span:nth-child(2) {
    font-size: 20px;
}

.back_button {
    letter-spacing: 4px;
    font-weight: 600;
    /* top: -18px; */
    padding: 4px 0px;
}

.break_buttons {
    width: 100%;
    padding: 0px 10px;
}

.brown_border{
    border: 1px solid #dad8d8ee !important;
}
.cancel_button {
    background: transparent;
    background-color: #f8f9f9 !important;
    margin-right: 10px;
    border: 1px solid 1px solid rgba(121, 119, 119, 0.6392156862745098);
    color: red;
    color: var(--std_teel);
}

button.back_button.col-12.breaking_buttons.mat-stroked-button.mat-primaryStrCus.ng-star-inserted {
    margin-bottom: 10px;
}

.frwd_button {
    letter-spacing: 3px;
    font-weight: 600;
    padding: 4px 0px;
}

.doc_add_validation {
    font-size: 12px;
    text-align: left !important;
    display: block;
    word-break: break-word;
}


.tooltipImg img {
    width: 12px;
    cursor: pointer;
}
.w-80px {
    min-width: 80px !important;
}
.w-50px {
    min-width: 50px !important;
}
@keyframes infinite-spinning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
        /* transform: scale(1.1); */
      }

      20%, 80% {
        transform: translate3d(2px, 0, 0);
        /* transform: scale(1); */
      }

      30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
        /* transform: scale(1.1); */
      }

      40%, 60% {
        transform: translate3d(4px, 0, 0);
        transform: scale(1.2);
      }
  }
  .CloseModel {
    text-align: right;
    color: #ccc;

    font-size: 20px;
}
.CloseModel img{
    width: 15px;
    cursor: pointer;
}
.statusWidth {
    max-width: 250px;
}
.import_button {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    left: 0pc;
    opacity: 0;
}
.profile_pic_container{
    position: relative;
    width: 200px;
    transition: all 2s;
}
.profile_pic_container img{
    width: 100%;
}
.profile_overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    color: #ffff;
    top: 0px;
    left: 0px;
    border-radius: 12px;
    background: #4f4b4b80;
    display: none;
}
.profile_overlay p{
    margin-top: 30px;
}
.profile_pic_container:hover .profile_overlay{
    display: block;

}
.close{
    text-align: right;
    width: 100%;

}
span.status_cnr {
    display: block;
    padding: 5px 10px;
    text-align: center;
    border-radius: 3px;
    color: #fff;
}
.btnclose {
    background-image: url(./assets/images/close-gray.svg);
    width: 20px;
    height: 20px;
    background-size: 14px 14px;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    border: 0;
    background-color: transparent;
    transition: all 1s ease;
}
.btnclose:hover{
    background-image: url(assets/images/close.svg);
}
.p-50 {
    padding: 50px !important;
}
.mt4_2per-auto{
    margin: 4.2% auto 0 auto;
}
.closeMenu .logo{
    visibility: hidden !important;
    opacity: 0 !important;
}
.logo{
    visibility: visible;
    opacity: 1;
    transition: all .5s ease 0s;
    max-width: 60px !important;
}
.toggle img{
margin: 10px 0px 10.7px 0px;
cursor: pointer;
}
.dpCnr {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 2px 1px #d9d9d9;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.head_wrap{
    background:#fcfcfc;
    padding: 14px;
    margin-bottom: 0px;
    border-bottom: 1px solid #eee;
}
.heightContent {
    height: -webkit-calc(50% - 20px);
    height: -moz-calc(50% - 20px);
    height: calc(100% - 20px);

}
.min-heightContent {
    min-height: calc(100% - 200px);
}
.contactEmail, .contactPhone {
    position: relative;
    padding-left: 35px;
    margin: 10px 0px;
    color: #212529;
}
.contactUsMenu{
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
.contactEmail,
.contactPhone:hover{
    text-decoration: none!important;
    color: #212529!important;
}
.contactEmail::before,.contactPhone::before{
    width: 25px;
    height: 25px;
    background-size: 20px;
    background-position: center;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
}
.contactEmail::before{
    background-image: url(assets/images/email.svg);
}
.contactPhone::before{
    background-image: url(assets/images/phone.svg);
}
.submenuPadding{
    padding-left: 15px;
}
.closeMenu .submenuPadding{
    padding-left: 0px !important;
}

.popup_header {
    border-bottom: 1px solid #ccc;
    margin-left: -24px;
    margin-right: -24px;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 10px;
    margin-top: -10px;
}
.bold {
    color: var(--std_teel);
    font-size: 1.4285em;
    font-weight: bolder;
}
.twoFaTableWrap{
    display: flex;
    align-items: center;
}
.twoFaTableIcon{
    width:30px;
}
.twoFaTableIcon img{
    width: 100%
}
.twoFaTableTitle{
    width: calc(100% - 30px);
    padding-left: 10px;
}
.mini_back img{
    width: 15px;
}
.terms {
    margin-bottom: 35px;
    border-bottom: 1px dashed #b5b0b0;
    padding-bottom: 14px;
    /* min-height: 60vh; */
}

.scrollTerms .terms:last-child{
    min-height: 55vh;
}
.h-300 {
    height: 300px;
    min-height: 300px;
    overflow: scroll;
}

.h-170 {
    height: 170px !important;
    min-height: 170px !important    ;
}
.mt-15 {
    margin-top: 15px !important;
}
a{
    color: black !important;
}
.highlighted {
    font-weight: 700;
    color: #007bff;
    cursor: pointer;
    display: inline;
}
.fixed_table .mat-column-Status{
    width: 160px;
}
.fixed_table{
    table-layout: fixed;
}
.fixed_table .mat-column-select{
    width: 50px;
    text-align: center;
}
.fixed_table .mat-column-Order-ID{
    width: 100px;
}
.TermsBusinessTitle {
    padding: 0px 22px;
    font-weight: bold;
    margin: 20px 0px 3px 0px;
    text-decoration: underline;
}
.addnewopt{
    color: #D4AD18;
    font-weight: 600;
    border-top: 1px solid #e9e8e8;

}
.terms span {
    text-align: justify;
    /* display: block; */
    /* word-break: break-all; */
    /* white-space: pre-line; */
    /* font-size: 12.8px; */
}



table.custom_mat_table  {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  .custom_mat_table td{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  .custom_mat_table th{
    background: #F8F8F9;
    border: 1px solid #eee;
    font-size: 14px;
    padding: 8px;
  }


  /* .custom_mat_table tr:nth-child(even) {
    background-color: #dddddd;
  } */

  img.cust_button{
    border: 1px solid #e6e4e4;
    padding: 6px;
    border-radius: 2px;
  }
  .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background{
      background: #b0b0b0 !important;

  }
  .mat-checkbox-disabled{
    cursor: no-drop !important;
  }
  .CloseModel span{
      cursor: pointer;
  }
  .minitable .cdk-column-Order-ID{
    width: 110px;
}
  .minitable .cdk-column-Order-Date , .minitable .cdk-column-Test-Date{
    width: 130px;
}
.borderedtable th.mat-header-cell:not(:last-child) , .borderedtable .mat-cell:not(:last-child){
border-right: none !important;
}
.borderedtable .mat-cell{
    border-top: 0px !important;
}
.tableRowEdit span{
    cursor: pointer;
}
.tableRowEdit .disableAct {
    opacity: 0.3;
    cursor: no-drop !important;
    position: relative;
    cursor: no-drop;
}
.tableRowEdit .disableAct::after{
    content: '';
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0%;
}
.removeUpload {
    right: -25px;
    top: -15px;
    z-index: 999;
    display: block;
    background: url(assets/images/close.svg);
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    cursor: pointer;
}
.max-width-200{
    max-width: 200px;
    margin: 0 auto;
}
.gearColSelect li{
    cursor: pointer;
}
.sidewrapper {
    width: 230px ;
    transition: all .2s ease 0s;
}
.mini_icons{
    margin-right:20px;
}
.mini_icons img{
width: 20px;
}
.mini_icons {
    cursor: pointer;
}

.bgGray{
    background: #f1f1f1;
}
.attachSingle {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    font-size: 14px;
    margin: 2px;
}
.attachName,.attachRemove{
    padding: 2px 11px;
}
.attachName{
    background: var(--std_teel);
    color: #fff;
}
.attachRemove{
    background: #ea0000;
    color: #fff;
    cursor: pointer;
    transition: all .500s ease;
}
.attachRemove:hover{
    background: red;
}
.cards_row{
    justify-content: space-between;

}
.cards_row .mat-card p:nth-child(1){
text-align: right;
}
.cards_row .mat-card {
    color: #ffffff;
}
.cards_row .card_singles:nth-child(1) .mat-card{
    background: #dfa586
}
.cards_row .card_singles:nth-child(2) .mat-card{
    background: #6cb8cc
}
.cards_row .card_singles:nth-child(3) .mat-card{
    background: var(--std_teel)
}
.cards_row .card_singles:nth-child(4) .mat-card{
    background: #efcd56
}
.cards_row .card_singles:nth-child(5) .mat-card{
    background: #67906f
}
.card_singles{
    width: 20%;
    cursor: pointer;
    padding: 5px;
}
.matMenuHeader{
    margin: -8px 0px;
    min-width: 390px;
}
.matMenuHeader ul{
    padding: 4px 0px !important;
}
.container_inner_load .circle:nth-child(2) {
    animation-delay: .2s;
  }

  .container_inner_load .circle:nth-child(3) {
    animation-delay: .4s;
  }
  .container_inner_load .circle:nth-child(4) {
    animation-delay: .6s;
  }

  .container_inner_load .circle:nth-child(5) {
    animation-delay: .8s;
  }

  .container_inner_load .circle{
    width: 5px;
    background: var(--std_gold);
    height: 5px;
    margin-left: 10px;
    border-radius: 50%;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  .container_inner_load {
    display: flex;
    width: 100%;
    align-items: center;
  }
  @keyframes blink {
    0% {
      opacity: .2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .2;
    }
  }
  .mat-simple-snackbar-action{

    color: #fff;
  }
  .custom-modalbox .modal_content{
      min-width: 450px;
  }
  .chat_flex{
      display: flex;
      align-items: flex-start;;
  }

  .chatTextinput {
    border: 1px solid #e2dfe2;
    border-radius: 4px;
    position: relative;
    padding: 5px 15px 30px;

  }


  .chatTextinput .chatInputArea{
    margin: 5px 0px 5px 0px;
    font-size: 14px;
  }
  .chatAttach{
    position: absolute;
    bottom: 5px;
    right: 10px;

  }

  .attachCover input[type="file"]{
    position: absolute;
    left: 0px;
    width: 100%;
    opacity: 0;
    z-index: 1;
  }
  .pastChatContainer{
    display: flex;
    margin-bottom: 15px;

  }
  .attach img{

    width: 25px;
  }

  .propic_container{
      margin-right: 12px;
  }

  .chatDetails{
      width: 95%;
      padding:0px 10px;
  }

  .pastChatContainer .name{
      margin-right: 5px;
  }
  .pastChatContainer .name, .pastChatContainer .time{
    font-size: 13px;
    font-family: AV-HEAVY;
  }
  .pastChatContainer .time{
  color:#928888;
  font-size: 12px;
}

.padding-left15 {
    padding-left: 15px !important;
}
.chatMessge{
    color: #000;
    font-size: 15px;
    word-break: break-word;
}
 .downloadContainer .attachClip img{
    width: 25px;
}
.downloadContainer{
    display: flex;
    align-items: center;
    padding: 6px;
    border: 1px solid #dcd7d7ee;
    border-radius: 4px;
    background: #eee;
}
.downloadContainer .ChatAttachName p {

margin-bottom: 0;
}

.error{
    font-size: 15px;
    color: red;
}
.tableIcon{
    width: 20px;
}
button.mat-stroked-button:disabled img {
    opacity: 0.2;
}
.warning{
    background-image: url('./assets/images/exclamation-mark.svg');
    width: 16px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    background-size: 100% 100%;
    margin-right: 5px;

}
.w100px{
    width: 100px;
}
.w25px{
    width: 25px;
}
.w50px{
    width: 50px;
}
.disableButton {
    opacity: 0.6;
    cursor: no-drop !important;
}
.disableButton .disableInput{
cursor: no-drop;
}

.attachCover img{

    width: 25px;
}



  @keyframes fadeAnimation{

   from{
    width: 25% !important;
   }
  to{
      width:100% !important;

  }


  }
  .fadePrest{
   /* width : 15%; */
   /* opacity: 0; */
   overflow: hidden;
   /* transition: all .7s ease-out; */
   animation: fadeAnimation 1s;
  }

  .col-FLex{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
.qusMark{
    position: relative;
}
.qusMark input{
    padding-right: 7px;
}
span.info-details {
    position: absolute;
    right: 0;
    bottom: 5px;
    color: var(--std_teel);
    font-weight: bold;
    cursor: pointer;
}
.matToolTip{
    font-size: 14px;
}
.min-w-tc .mat-dialog-content {
    min-width: 1000px;
}


.iconTextPair{
    width: 125px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


span.UserAcNo {
    font-weight: 600;
    font-size: 12px;
    color: #0c404d;
}
.error-mat{
    position: absolute;
    bottom: -19px;
    font-size: 12px;
    color: #f44336;
}
.error-mat-undeline .mat-form-field-underline{
    background-color: rgba(216, 57, 46, 0.69) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: var(--std_teel) !important;
}

.miniClose{
    cursor: pointer;
    color: #b5acac;
    margin-left: 10px;
}
.miniClose:hover{
    color: #f44336;
}
.chat_flex .chatMessge p{
    text-align: justify;
}
.commentsAttach{
    position: absolute;
    bottom: 5px;
    width: 100%;
    text-align: right;
    padding: 0px 30px;
    right: 0px;
    font-family: AVPB;
}

.dpCnr._comment{
    border-radius: 4px !important;
}
.chatHistory{

    overflow: hidden;
    margin-bottom: 25px;
}
.scrollCommentSection:hover{
    overflow: auto !important;
}
.scrollCommentSection{
max-height: 250px;
overflow: hidden;
}
.limit_height {
    max-height: 400px;
    overflow: auto;
}

.chatCover{
    padding: 10px 30px;
}
.hyperColor{
    color: #007bff;
   cursor: pointer;

   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: block;
   /* width: 100%; */
}

   .productlist_card .mat-card-header-text{
    margin: unset !important;
}

.message_details .message_overflow{
    max-height: 300px;
    overflow: auto;
}



.long_text_container{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}


.scrollling_table th.mat-header-cell {
    width: 200px;
    white-space: nowrap;
}

.removeMark:after{
    display: none !important;
}


.swatch ul li p {
    margin: 0px;
    font-size: 14px;
}
.graphCard{
    height: 100%;
}

.termsTitle {
    padding-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: var(--std_teel);
    text-decoration: underline;
}
.cpIndicator.dot_ind ul{
    justify-content: unset !important;

}
.cpIndicator.dot_ind ul li{
    margin-right: 15px;
}
.cpIndicator.dot_ind ul li::before{
    display:  none !important;
}
.cpIndicator.dot_ind p {
    padding-left: 10px;
}

.tabpromt p {
    text-align: center;
    color: #7595e6;
    display: table-cell;
    font-family: MB;
    font-size: 25px;
    vertical-align: middle;
}
.tabpromt {
    display: table;
    width: 100%;
    position: fixed;
    height: 100%;
    background: #fff;
    left: 0px;
    z-index: 100;
}
@media only screen and (min-width: 901px){
    .tabpromt{
        display: none;
    }



}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}
.custom_Errors .errorMessage{
 position: absolute;
bottom: -25px;
left: 0px;
}

.custom_Errors{
    position: relative;
}
.custom_Errors .errorMessage{
    font-size: 0.9em;
}

.custom_Errors .errorMessage.warningMessage{
    color: var(--std_gold);

}

.custom_Errors .errorMessage.warningMessage:before {
    background: url(./assets/images/warning-board.png) no-repeat 0px 4px;
    display: inline-block;
    width: 20px;
    height: 23px;
    content: "";
    background-size: 100%;
    vertical-align: -15%;
}
.mw-400{
    max-width: 400px;
}
.wrapText {
    word-break: break-all;
    width: 100%;
    overflow: hidden;
    /* padding: 2px 10px; */
    white-space: normal;
}
.cursor-pointer{
    cursor: pointer;
}
.unlockmenu img{
    width: 25px;
}
 .unlockmenu .mat-menu-content{
    padding: 0px !important;
}

.input_with_button .edit img{
    width: 30px;
    cursor: pointer;
}
.highlightselect , .highlightselect span{
    color: #d4ad19 !important;
    font-weight: 700 !important;
}

.disablecol{
    cursor: no-drop;
    background-image: url(assets/images/checked.svg) !important;
    background-color: #fff !important;
    background-size: cover !important;
}


.popupTitle_rules + .ml-auto {
    display: flex;
}


@-moz-document url-prefix() {
    .custom_Errors .errorMessage{
        font-size: .76em;
    }

  }

  .GraphData p {
    margin: 0;
    color: #0c404d;
}
.GraphDataCount {
    font-size: 20px;
    font-weight: bold;
}

.graphText-relative{
    position: relative;
}
.graphText-relative .GraphData{
    position: absolute;
    left: 30px
}


.amount_sapn{
    display: block;
    width: 100%;
    text-align: right;
}
.float-left{
    float: left !important;
}
span.eTax:after,span.cTax:after{
    padding: 5px;
    border-radius: 50%;
    width: 25px;
    display: flex;
    height: 25px;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: 5px;
    font-weight: bold;
}
span.eTax:after {
    content: 'E';
    background: var(--std_gold);
    color: #fff;

}
span.cTax:after {
    content: 'C';
    background: green;
    color: #fff;
}
span.eTax,span.cTax {
    display: flex;
    align-items: center;
    margin: 1px 0px;
}
.emptyTax{
    padding-right: 30px;
}
.autoDeactDate .mat-form-field-wrapper {
    padding-bottom: 10px !important;
}
.autoDeactDate .mat-form-field-flex,
.autoDeactDate .mat-form-field-infix,
.autoDeactDate .mat-form-field-subscript-wrapper{
    padding: 0 !important;
}
.autoDeactDate .mat-form-field-underline{
    display: block !important;
    position: relative;
    top: -3px;
    width: calc(100% - 23px);
}
.uploadBtn{
    padding: 1px 15px !important;
}
.commentAttachPos{
    display: flex;
    justify-content: flex-end;
}
.singleTab{
    display: none;
}
.singleTab.singletabActive{
    display: block;
}

.suffixIcon.owPercent{
    opacity: 0.5;
}
.warning-order{
    color: #e2574c;
    position: relative;
    padding-left: 21px;
    display: block;
}
.warning-order:before{
    content: '';
    background-image: url('./assets/images/exclamation-mark.svg');
    width: 16px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    background-size: 100% 100%;
    margin-right: 5px;
    position: absolute;
    left: 0;
    top: 1px;
}

.short_table{
    width: auto !important;
}
.mat-abs-footer{
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
}
.custom-modalbox .mat-dialog-container{
    position: relative;
}
 
.share_row {
    background: url("assets/images/share.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    padding: 0px;
    min-width: 0px !important;
}
.archive_row{
    background: url("/assets/images/archive.svg");
    /* background: url('assets/images/archive-icon.png'); */
    background-size: contain;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    padding: 0px;
    min-width: 0px !important;
}
.no-found{
    text-align: center;
    font-weight: bold;
    color: #0d404d;
    font-size: 15px;
}
.end-center{
    justify-content: flex-end !important;
    align-items: center;
}
button:disabled{
    opacity: .4;
    cursor: not-allowed !important;
}
.padding_zero{
    padding: 0 !important;
}
.el_label{
    word-break: break-all;
}
.el_label label{
    margin-bottom: 0px !important;
    font-size: 10px;
    color: #3e707dab
  }
  .end-center {
    justify-content: flex-end!important;
    align-items: center;
    width: 100%;
}
.FileFilter{
    margin-right: 1rem;
}
.FileFilter .mat-radio-button{
    margin-right: 1rem;
}
button:focus,
img:focus{
    outline: none !important;
}
.NoDataFound{
    width: 100%;
    text-align: center;
    color: #0d404d;
    font-weight: 500;
    display: block;
}
/* mat-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-page-size {
    height: 55px !important;
} */
.info_img{
    display: flex;
    justify-content: center;
    align-items: center;
}
.info_img img{
    width: 15px;
    margin-left: 10px;
}
.matIcon {
  color: var(--std_teel);
}
.no-msg{
    color: #10404d;
    text-align: center;
    padding: 25px 0px;
  }
  .no-msg img{
    margin-left: 20px;
    width: 30px;
  }
  .send-btn{
    width: 25px;
    height: 25px;
    cursor: pointer !important;
    box-shadow: none !important;
    padding: 0px !important;
    min-width: unset !important;
    margin-left: 15px !important; 
    background-image: url('./assets/images/send-button.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .send-btn:disabled{
    box-shadow: none !important;
    background-color: transparent !important;
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  .symbol{
    width: 30px;
    display: inline-block;
  }
  *:focus{
      outline: none !important;
  }
  .more {
    width: 100px;
    margin: auto;
    border: 1px solid lightgray;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    padding: 5px;
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 1400px){
    .radio{
        width: 100% !important;
        font-size: 13px !important;
    }
  }

  .td_flex{
    display: flex;
    align-items: center;
    word-break: break-all;
  }
  .pii_badge{
    background-color: #d4b033;
    color: white !important;
    padding: 1px 7px;
    border-radius: 3px;
}
.virus-icon{
    width: 2rem !important;
    margin-left: 10px;
}
.noCursor{
    opacity: 0.6;
    cursor: no-drop;
}
#cdk-overlay-1 .mat-dialog-container{
    overflow: unset !important;
    }
.shared-icon{
    width: 17px !important;
    filter: invert(17%) sepia(56%) saturate(754%) hue-rotate(146deg) brightness(97%) contrast(90%);
    margin: 0px 5px;
}
.pdf-ellipsis{ 
    font-family: AVR;
    color: gray;
    font-size: 1em;
    font-weight: 600;
    padding: 10px 0px 3px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
}
.blue_button{
    color: white !important;
    background-color: #0d404d !important;
}
.blue_button:disabled{
    opacity: 0.8 !important;
}
.ph-icon{
    width: 20px !important;
    margin-right: 5px !important;
    display: inline-block !important;
    vertical-align: top !important;
  }
  .ph-icon img{
    width: 100% !important;
  }
  .mat-tooltip{
    word-break: break-all !important; 
 } 
 iframe { overflow: hidden; }

 .dynamic-profile-container {
    padding: 10px;
    text-align: center;
    width: 40px !important;
    height: 40px !important;
    border-radius: 20px;
    color: white;
  }


.new_status {
    background:  #698DBE;
}
.in_progress_status{
    background: #038DAF;
}
.in_review_status{
    background: #007BFF;
}
.gold {
    background: #D6B124;
}
.declined_status {
    background: rgb(236, 195, 192);
}